.cardlist__header {
  color: #000;
  font-size: 20px;
  font-weight: 700;
}
.cardlist__container {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  margin-top: 20px;
}
