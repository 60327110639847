.itemModal {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}
.itemModal__content {
  max-width: 496px;
  position: relative;
  display: flex;
  flex-direction: column;
}
.itemModal__info {
  background: white;
  border-radius: 0 0 20px 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 32px;
}
.itemModal__text {
  font-size: 20px;
  font-weight: 700;
  margin: 0;
}
.itemModal__text-delete {
  color: red;
}
.itemModal__text-delete:hover {
  cursor: pointer;
}
.itemModal__close {
  position: absolute;
  bottom: calc(100% - 34px);
  left: calc(100% - 34px);
  width: 20px;
  height: 20px;
  opacity: 0.5;
}
.itemModal__close:hover {
  cursor: pointer;
  transition: 0.2s;
  opacity: 1;
}
.itemModal__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px 20px 0 0;
}
.itemModal__info-line {
  display: flex;
  justify-content: space-between;
}
