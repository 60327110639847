.weather {
  position: relative;
}
.weather__info {
  position: absolute;
  top: 12px;
  left: 16px;
  color: #fff;
  font-size: 36px;
  font-weight: 700;
  margin: 0;
}
.weather__bg {
  width: 100%;
}
@media (max-width: 768px) {
  .weather__info {
    top: 8px;
    font-size: 24px;
  }
}
@media (max-width: 576px) {
  .weather__info {
    top: 4px;
    font-size: 18px;
  }
}
@media (max-width: 320px) {
  .weather__info {
    top: 2px;
    font-size: 16px;
  }
}
