.header {
  display: flex;
  justify-content: space-between;
  margin: 0 40px;
}
.header__text {
  color: black;
  font-weight: 700;
  font-size: 20px;
  font-style: normal;
  line-height: 24px;
}
.header__left {
  margin: 0;
  display: flex;
  align-items: center;
  gap: 20px;
}
.header__right {
  margin: 0;
  display: flex;
  align-items: center;
  gap: 20px;
}
.header__button {
  background: none;
  border: none;
}
.header__button:hover {
  cursor: pointer;
}
.header__avatar {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
}
