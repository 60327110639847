.card {
  position: relative;
}
.card__name {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translate(-50%);
  margin: 0;
  padding: 2px 12px;
  background: #fff;
  border-radius: 4px;
  border-style: none;
}
.card__name:hover {
  cursor: pointer;
}
.card__image {
  width: 328px;
  height: 328px;
  border-radius: 10px;
}
.card__image:hover {
  cursor: pointer;
}

.card__like {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translate(75%);
  margin: 0;
  padding: 2px 12px;
  border-radius: 4px;
  border-style: none;
}

.card__like:hover {
  cursor: pointer;
}
