.modal {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}
.modal__content {
  background-color: #fff;
  max-width: 496px;
  width: 100%;
  border-radius: 20px;
  padding: 34px 26px;
  position: relative;
  display: flex;
  flex-direction: column;
}
.modal__input {
  border: none;
  border-bottom: 1px solid #000;
  width: 100%;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  padding-bottom: 5px;
}
.modal__inputs {
  display: flex;
  gap: 24px;
  flex-direction: column;
}
.modal__heading {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.modal__button {
  background: black;
  color: white;
  opacity: 0.5;
  margin-top: 34px;
  padding: 16px;
  font-weight: 700;
  font-size: 16px;
  border-radius: 4px;
}
.modal__button:hover {
  cursor: pointer;
  opacity: 1;
  transition: 0.2s;
}
.modal__close {
  position: absolute;
  top: 20px;
  right: 28px;
  width: 20px;
  height: 20px;
}
.modal__close:hover {
  cursor: pointer;
  transition: 0.2s;
  opacity: 0.5;
}
.modal__radio {
  font-size: 16px;
  font-weight: 700;
  display: flex;
  gap: 11px;
  align-items: center;
}

.modal__radio-input:hover {
  cursor: pointer;
}
.modal__radio-input {
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 15px;
  background-color: #fff;
  display: inline-block;
  border: 2px solid black;
}

.modal__radio-input:checked {
  transition: 0.2s;
  background-image: radial-gradient(
    circle,
    black 0%,
    black 50%,
    transparent 50%,
    transparent 100%
  );
}
.modal__radios {
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
}
.modal__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
}
