.switch {
    display: flex;
    position: relative;
}

.switch__input {
    border-radius: 20px;
    border: 2px solid #ccc;
    width: 50px;
    height: 30px;
    appearance: none;
}

.switch__slider {
    border-radius: 25px;
    width: 28px;
    height: 28px;
    top: 4px;
    background-color: #000;
    color: #fff;
    text-align: center;
    cursor: pointer;
    position: absolute;
}

.switch__slider-F {
    left: 30px;
    transition: 0.3s;
}

.switch__slide-F:hover {
    left: 2px;
}

.switch__slider-C {
    left: 5px;
    transition: 0.3s;
}

.switch__active {
    font-size: 16px;
    color: #fff;
}

.switch__temp-C {
    position: absolute;
    top: -7px;
    left: 38px;
    color: #ccc;
    font-weight: 700;
}

.switch__temp-F {
    position: absolute;
    top: -7px;
    left: 14px;
    color: #ccc;
    font-weight: 700;
}
