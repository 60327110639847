.profile {
  margin-top: 40px;
  display: flex;
}
.profile__info {
  padding-right: 300px;
  padding-left: 18px;
}
.profile__heading {
  font-size: 20px;
  font-weight: 700;
  padding-left: 40px;
}
.profile__heading span {
  font-size: 20px;
  font-weight: 700;
  opacity: 0.6;
  padding-left: 20px;
}
.profile__heading span:hover {
  opacity: 1;
  cursor: pointer;
}
.profile__cards {
  gap: 16px;
  padding-left: 18px;
}

.profile__loading {
  margin-top: 24px;
  font-size: 56px;
  text-align: center;
}
